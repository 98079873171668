import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Parser } from "html-to-react";
import axios from "axios";
import { HOMEO_EVENT_GET } from "../Utils/InfoUploadingConst";

function EventsDetails() {
  const locate = useLocation();
  const navigate = useNavigate();

  const x = locate.state.data.date.split("T")[0];
  const [data, setData] = useState(locate.state.data);
  const [images, setImages] = useState(
    Array.isArray(locate.state.images) ? locate.state.images : []
  );
  const htmlParser = new Parser();

  const college_id = data?.college_id;
  const [info, setInfo] = useState([]);

  const fetchData = async (collegeId) => {
    const config = {
      method: "get",
      url: HOMEO_EVENT_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios(config);
      const responseData = response.data.data;

      // Sort and filter data
      responseData.sort(customSortByDate);
      const recentData = responseData
        .filter(
          (element) =>
            element.college_id === data.college_id &&
            element.status === "ACTIVE"
        )
        .slice(0, 4);

      setInfo(recentData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    // Parse the images correctly
    if (data?.attachments) {
      setImages(JSON.parse(data?.attachments));
    }
    // Fetch recent events when data changes
    fetchData(data?.college_id);
  }, [data]);

  function customSortByDate(item1, item2) {
    return item2.date.localeCompare(item1.date);
  }

  // const handleClick = (item) => {
  //   setData(item);
  //   setImages(JSON.parse(item.attachments)); // Ensure the images are parsed correctly
  //   navigate(`${locate.pathname}/${item.id}`, {
  //     state: {
  //       images: JSON.parse(item.attachments),
  //       data: item,
  //     },
  //   });
  // };

  const handleClick = (item) => {
    setData(item);
    setImages(JSON.parse(item.attachments)); // Ensure the images are parsed correctly

    // Use the pathname of the current location, removing the `item.id` part
    const newPath =
      locate.pathname.split("/eventsDetails")[0] + "/eventsDetails/" + item.id;

    navigate(newPath, {
      state: {
        images: JSON.parse(item.attachments),
        data: item,
      },
    });
  };

  console.log("locate.pathname -", locate.pathname);

  return (
    <div className="container-fluid register" style={{ maxWidth: "90%" }}>
      <h3>EVENT DETAILS</h3>
      <div className="row">
        <div className="col-sm-8">
          <div className="events-details-left-content pr-20">
            {images.length > 1 ? (
              <div className="banner-area">
                <div
                  id="carouselExampleCaptions"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-indicators">
                    {images.map((item, index) => (
                      <button
                        key={index}
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide-to={index}
                        className={index === 0 ? "active" : ""}
                        aria-label={`Slide ${index + 1}`}
                      />
                    ))}
                  </div>
                  <div className="carousel-inner">
                    {images.map((item, index) => (
                      <div
                        key={index}
                        className={`carousel-item ${
                          index === 0 ? "active" : ""
                        }`}
                        data-bs-interval="7000"
                      >
                        <img
                          src={item}
                          className="d-block w-100 img-fluid h-80 main-slider"
                          alt="..."
                        />
                      </div>
                    ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    />
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            ) : (
              <div className="events-image text-center">
                {images[0] && images[0].endsWith(".pdf") ? (
                  <iframe
                    src={`${images[0]}#toolbar=0`}
                    className="img-fluid"
                    style={{ width: "100%", height: "80vh" }}
                    title="PDF Display"
                  />
                ) : (
                  <img className="img-fluid" src={images[0]} alt="Image" />
                )}
              </div>
            )}
            <div className="meetings mt-3">
              <h2>{data?.title}</h2>
              <p className="text-muted">
                {x.slice(-2)} - {data?.month} - {data?.year}
              </p>
              <p>{htmlParser.parse(data?.description)}</p>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="tranding">
            <h3>Recent Events</h3>
            {info.map((item, index) => {
              const attachmentsArray = JSON.parse(item.attachments);
              const firstImage = attachmentsArray[0] || "";
              const isPDF = firstImage.endsWith(".pdf");

              return (
                <div className="tranding-box" key={index}>
                  <a
                    onClick={() => handleClick(item)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="tranding-content">
                      {isPDF ? (
                        <img src="/assets/images/pdfIcon.png" alt="PDF File" />
                      ) : (
                        <img src={firstImage} alt="Image" />
                      )}
                      <h4>{item.title}</h4>
                      <p>{item?.date.split("T")[0]}</p>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventsDetails;
