import React from "react";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Router/RouterConfig";
import { profileData } from "../../Pages/About/AutoritiesOfUniversity/ProfileData";
import "./Navbar.scss";
import {
  ACADEMIC_CALENDAR_GET,
  ADD_COMMITTEES,
  STAFF_DETAILS_NEW_GET,
} from "../../Utils/InfoUploadingConst";

function Navbar_engineering() {
  const navigate = useNavigate();

  const changeDir = (i) => {
    navigate(ROUTES.Profile, { state: { data: i } });
  };

  const [info, setInfo] = useState([]);
  const [data, setData] = useState([]);
  const [staffdata, setStaffData] = useState([]);

  const getData = async () => {
    const config = {
      method: "get",
      url: ADD_COMMITTEES,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        res.data.data.forEach((element) => {
          element.attachment = JSON.parse(element.attachment);
        });
        res.data.data.sort((a, b) => b.id - a.id);
        const activeData = res.data.data.filter(
          (element) =>
            element.status === "ACTIVE" && element.college_id === 1111000
        );
        console.log("activeData -", activeData);
        setInfo(activeData);
      })
      .catch((err) => {
        console.log(err);
      });

    const config2 = {
      method: "get",
      url: ACADEMIC_CALENDAR_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config2)
      .then((res) => {
        // res.data.data.sort((a, b) => b.session - a.session);
        res.data.data.forEach((element) => {
          element.attachments = JSON.parse(element.attachments);
        });
        res.data.data.sort((a, b) => b.id - a.id);
        // console.log(res.data.data)
        const feeData = res.data.data.filter(
          (element) =>
            element.status === "ACTIVE" && element.college_id === 1111000
        );
        setData(feeData);
      })
      .catch((err) => {
        console.log(err);
      });

    const config3 = {
      method: "get",
      url: STAFF_DETAILS_NEW_GET,
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios(config3)
      .then((res) => {
        // res.data.data.forEach((element) => {
        //   element.attachment = JSON.parse(element.attachment);
        // });
        res.data.data.sort((a, b) => b.id - a.id);
        // console.log(res.data.data)
        // const feeData = res.data.data.filter(
        //   (element) =>
        //     element.status === "ACTIVE" && element.college_id === 1111000
        // );
        // Filter and process the data
        const feeData = res.data.data.filter((element) => {
          // Check if attachment is in a JSON-stringified format and parse it
          if (
            typeof element.attachment === "string" &&
            element.attachment.startsWith('"') &&
            element.attachment.endsWith('"')
          ) {
            element.attachment = JSON.parse(element.attachment);
          }

          // Filter the data based on the status and college_id
          return element.status === "ACTIVE" && element.college_id === 1111000;
        });
        setStaffData(feeData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  console.log("staffdata -", staffdata);

  return (
    <div className="Navbar-engineering">
      <div className="top-header-area">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-4 col-sm-4">
              <div className="header-right-content">
                <marquee className="scroll-text text-white">
                  <p className="text-white">
                    Toll free number to report ragging :{" "}
                    <a href="tel:1800 843 2900" className="text-light">
                      1800 843 2900
                    </a>{" "}
                  </p>
                </marquee>
              </div>
            </div>

            <div className="col-lg-8 col-sm-8">
              <div className="header-right-content">
                <div className="list">
                  <ul>
                    <li className="nav-item">
                      <Link
                        to={"/engineering/Recruitment"}
                        className="nav-link"
                      >
                        Recruitment
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://ums.swaminarayanuniversity.ac.in/"
                        target="_blank"
                      >
                        UMS Login
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        <div className="navbar-area nav-bg-1">
          <div className="mobile-responsive-nav">
            <div className="container">
              <div className="mobile-responsive-menu">
                <div className="logo">
                  <a href="/engineering">
                    <img
                      src="/assets/images/white-logo.png"
                      className="main-logo"
                      alt="logo"
                    />
                    <img
                      src="/assets/images/white-logo.png"
                      className="white-logo"
                      alt="logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="desktop-nav">
            <div className="container-fluid">
              <nav className="navbar navbar-expand-md navbar-light">
                <a className="navbar-brand" href="/engineering">
                  <img
                    src="/assets/images/white-logo.png"
                    width="250px"
                    alt="logo"
                  />
                </a>
                <h5 class="text-left">
                  Shree Swaminarayan Engineering College, Kalol
                </h5>
                <div
                  className="collapse navbar-collapse mean-menu"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                      <Link to={"/engineering"}>Home</Link>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Overview"
                      >
                        Overview <span className="caret" />
                      </a>
                      <ul className="dropdown-menu nav-item">
                        <li className="nav-item">
                          <Link to={"/engineering/AboutUs"}>About SSVG</Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/engineering/FoundersMessage"}>
                            Founder Message
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/engineering/PrincipalMessage"}>
                            Principal Message
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/engineering/MissionVision"}>
                            Mission Vision
                          </Link>
                        </li>
                        <li className="dropdown nav-item">
                          <a
                            aria-expanded="true"
                            aria-haspopup="true"
                            role="button"
                            data-toggle="dropdown"
                            className="dropdown-toggle"
                            href="#"
                            title="Overview"
                          >
                            Office Bearers <span className="caret" />
                          </a>
                          <ul className="dropdown-menu nav-item">
                            <li className="nav-item">
                              {/* <a
                                onClick={() => {
                                  changeDir(profileData[0]);
                                }}
                                className="nav-link custom-div-link"
                              >
                                President
                              </a> */}

                              <Link
                                to={"/engineering/President"}
                                className="nav-link"
                              >
                                President
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                to={"/engineering/VicePresidents"}
                                className="nav-link"
                              >
                                Vice Presidents
                              </Link>
                            </li>
                            <li className="nav-item">
                              {/* <a
                                onClick={() => {
                                  changeDir(profileData[4]);
                                }}
                                className="nav-link"
                              >
                                Provost (Vice-Chancellor)
                              </a> */}
                              <Link
                                to={"/engineering/Provost-Profile"}
                                className="nav-link"
                              >
                                Provost (Vice-Chancellor)
                              </Link>
                            </li>
                            <li className="nav-item">
                              {/* <a
                                onClick={() => {
                                  changeDir(profileData[7]);
                                }}
                                className="nav-link custom-div-link"
                              >
                                Registrar
                              </a> */}
                              <Link
                                to="/engineering/Registrar-Profile"
                                className="nav-link"
                              >
                                Registrar
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="College"
                      >
                        College <span className="caret" />
                      </a>
                      <ul className="dropdown-menu nav-item">
                        <li className="nav-item">
                          <Link to={"/engineering/AboutCollege"}>
                            About College
                          </Link>
                          <Link to={"/engineering/Infrastructure"}>
                            Infrastructure
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link to={"#"}>
                            College Council
                          </Link>
                        </li> */}
                        <li className="nav-item">
                          <Link to={"/engineering/Funding"}>Funding</Link>
                        </li>
                        {/* <li className="nav-item">
                          <a
                            href="#"
                            // target="_blank"
                          >
                            College Committee
                          </a>
                        </li> */}

                        <li className="nav-item">
                          <Link to="/engineering/Permission" title="Permission">
                            Permission
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/engineering/Affiliation"
                            title="Affiliation"
                          >
                            Affiliation
                          </Link>
                        </li>

                        {/* <li className="nav-item">
                          <Link to={"/engineering/Courses"}>Courses</Link>
                        </li> */}

                        {/* <li className="nav-item">
              <a href="#" title="Facilities">
                Facilities
              </a>
            </li>
         <li className="nav-item nav-item">
              <a href="#" title="Departments">
                Departments
              </a>
            </li> */}
                      </ul>
                    </li>
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Academics"
                      >
                        Academics <span className="caret" />
                      </a>
                      <ul className="dropdown-menu">
                        {/* <li className="nav-item">
                          <Link to={"#"}>Admissions</Link>
                        </li> */}
                        <li className="nav-item">
                          <Link to={"/engineering/Eligibility"}>
                            {/* Eligibility */}
                            Admission details with Eligibility Criteria
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/engineering/AlumniMeetings"}>
                            Alumni Meetings
                          </Link>
                        </li>

                        {/* <li className="nav-item">
                          <Link to={"/engineering/Achievements-Awards-Medals"}>Achievements</Link>
                        </li> */}
                        <li className="nav-item">
                          <Link to={"/engineering/CampusPlacements"}>
                            Campus Placements
                          </Link>
                        </li>

                        {data?.filter((d) => d.type === "Fee Structure")
                          .length !== 0 ? (
                          data
                            ?.filter((d) => d.type === "Fee Structure")
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachments}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  Fee Structure
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a
                              href="/engineering/"
                              target="_blank"
                              className="nav-link"
                            >
                              Fee Structure
                            </a>
                          </li>
                        )}

                        {/* <li className="nav-item">
                          <Link to={"/engineering/FeeStructure"}>
                            {" "}
                            Fee Structure
                          </Link>
                        </li> */}

                        {data?.filter((d) => d.type === "Courses Details")
                          .length !== 0 ? (
                          data
                            ?.filter((d) => d.type === "Courses Details")
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachments}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  Courses
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a
                              href="/engineering/"
                              target="_blank"
                              className="nav-link"
                            >
                              Courses
                            </a>
                          </li>
                        )}

                        {/* <li className="nav-item">
                          <Link to={"/engineering/RulesandRegulations"}>
                            {" "}
                            Rules & Regulations{" "}
                          </Link>
                        </li> */}
                        <li className="nav-item">
                          <Link to="/engineering/AcademicCalender">
                            {" "}
                            Academic Calender
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link to="/engineering/Workshop">
                            {" "}
                            Workshop / Seminar / Industrial Visit{" "}
                          </Link>
                        </li> */}
                        {/* <li className="nav-item">
                          <a href="/assets/science/research-activity.pdf" target="_blank">
                            {" "}
                            Research Activity{" "}
                          </a>
                        </li> */}
                        {/* <li className="nav-item">
                          <a href="/assets/science/research-paper.pdf" target="_blank">
                            {" "}
                            Research Papers{" "}
                          </a>
                        </li> */}
                        <li className="nav-item">
                          <Link to={"/engineering/Syllabus"}>Syllabus</Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/engineering/studentList"}>
                            Students List
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to={"/engineering/resultsList"}>Results</Link>
                        </li>
                      </ul>
                    </li>
                    {/* <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Academics"
                      >
                        Engineering <span className="caret" />
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item">
                          <a
                            href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/CBDC+First+BHMS/Introduction+to+CBDC.pdf"
                            target="_blank"
                          >
                            Introduction
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/CBDC+First+BHMS/Letter+reg.+formation+of+college+level+Competency+Based+Dynamic+Curriculum+(CBDC)+implementation+Committee.pdf"
                            target="_blank"
                          >
                            Reg. Letter
                          </a>
                        </li>
                        <li className="dropdown nav-item">
                          <a
                            aria-expanded="true"
                            aria-haspopup="true"
                            role="button"
                            data-toggle="dropdown"
                            className="dropdown-toggle"
                            href="#"
                            title="Academics"
                          >
                            Subjects
                            <span className="caret" />
                          </a>
                          <ul className="dropdown-menu">
                            <li className="nav-item">
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/CBDC+First+BHMS/Anatomy%2C+Histology+and+Embryology.pdf"
                                target="_blank"
                              >
                                Anatomy, Histology & Embryology
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/CBDC+First+BHMS/Human+physiology+%26+Bio-Chemistry.pdf"
                                target="_blank"
                              >
                                Human Physiology & Biochemistry
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/CBDC+First+BHMS/Homoeopathic+Pharmacy.pdf"
                                target="_blank"
                              >
                                {" "}
                                Homeopathic Pharmacy
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/CBDC+First+BHMS/Homoeopathic+Materia+Medica.pdf"
                                target="_blank"
                              >
                                {" "}
                                Homeopathic Materia Medica
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/CBDC+First+BHMS/Org.+of+Medicine+%26+Physiology+PDF.pdf"
                                target="_blank"
                              >
                                {" "}
                                Organon of Medicine & physiology
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/CBDC+First+BHMS/Homoeopathic+Repertory+and+Case+Taking.pdf"
                                target="_blank"
                              >
                                {" "}
                                Homeopathic Repertory & Case taking
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                href="https://s3.ap-south-1.amazonaws.com/document.swaminarayanuniversity.ac.in/university-assets/Homoeopathy_Assets/CBDC+First+BHMS/Yoga+for+Health+Promotion.pdf"
                                target="_blank"
                              >
                                {" "}
                                Yoga for Health Promotion
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li> */}
                    <li className="dropdown nav-item">
                      <a
                        aria-expanded="true"
                        aria-haspopup="true"
                        role="button"
                        data-toggle="dropdown"
                        className="dropdown-toggle"
                        href="#"
                        title="Staff"
                      >
                        Staff <span className="caret" />
                      </a>
                      <ul className="dropdown-menu">
                        {/* <li className="nav-item">
                          <Link to={"/engineering/PrincipalMessage"}>
                            Principal
                          </Link>
                        </li> */}
                        {/* <li className="nav-item">
                          <Link
                            to="/engineering/TeachingStaff"
                           
                          >
                            {" "}
                            Teaching Staff
                          </Link>
                        </li> */}

                        {/* <li className="nav-item">
                          <Link to="/engineering/non-teachingStaff">
                            {" "}
                            Non-Teaching Staff
                          </Link>
                        </li> */}

                        {staffdata?.filter(
                          (d) => d.emp_category === "TEACHING STAFF"
                        ).length !== 0 ? (
                          staffdata
                            ?.filter((d) => d.emp_category === "TEACHING STAFF")
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachment}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  Teaching Staff
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a
                              href="/engineering"
                              target="_blank"
                              className="nav-link"
                            >
                              Teaching Staff
                            </a>
                          </li>
                        )}

                        {staffdata?.filter(
                          (d) => d.emp_category === "NON-TEACHING STAFF"
                        ).length !== 0 ? (
                          staffdata
                            ?.filter(
                              (d) => d.emp_category === "NON-TEACHING STAFF"
                            )
                            ?.map((item, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  href={item?.attachment}
                                  target="_blank"
                                  className="nav-link"
                                >
                                  Non-Teaching Staff
                                </a>
                              </li>
                            ))
                        ) : (
                          <li className="nav-item">
                            <a
                              href="/engineering/"
                              target="_blank"
                              className="nav-link"
                            >
                              Non-Teaching Staff
                            </a>
                          </li>
                        )}
                      </ul>
                    </li>
                    <li className="nav-item">
                      <Link to={"/engineering/events"}>Events</Link>
                    </li>
                    <li className="nav-item">
                      <Link to={"/engineering/ResearchandPublicationEng"}>
                        Research and Publication
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to={"/engineering/ContactUs"}>Contact Us</Link>
                    </li>
                    <li className="nav-item">
                      <a href="#" title="Member Menu">
                        <i className="glyphicon glyphicon-user" />
                      </a>
                    </li>{" "}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Navbar_engineering;
